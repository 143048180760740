import { create } from 'zustand'

import { TTProfileTripsResp, TTripData, TTripType } from '@api/types/profile/trips'

type TFlatTripData = {
  tripData: TTripData
  type: TTripType
}

type TProfileStore = {
  trips: TFlatTripData[]
  setTrips: (data: TTProfileTripsResp) => void
  updateSelectedTrip: (updatedTrip: TTripData) => void
  singleTrip: TTripData | null
  setSingleTrip: (data: TTripData) => void
}

const useProfileStore = create<TProfileStore>((set) => ({
  trips: [],
  singleTrip: null,
  setSingleTrip: (data) => {
    set({
      singleTrip: data
    })
  },
  setTrips: (data) => {
    let flatTrips = []

    for (const trip of data.active) {
      flatTrips.push({ tripData: trip, type: TTripType.ACTIVE })
    }
    for (const trip of data.history) {
      flatTrips.push({ tripData: trip, type: TTripType.HISTORY })
    }

    set({
      trips: flatTrips
    })
  },
  updateSelectedTrip: (updatedTrip: TTripData) => {
    set((state) => {
      const tripExists = state.trips.some((item) => item.tripData.trip.id === updatedTrip.trip.id)

      if (!tripExists) return state

      return {
        trips: state.trips.map((item) =>
          item.tripData.trip.id === updatedTrip.trip.id ? { ...item, tripData: updatedTrip } : item
        )
      }
    })
  }
}))

export { useProfileStore }
