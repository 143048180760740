// @INFO: /profile/trips API Data Models

import { TCarHost } from '../vehicles'
import { TTripPayment } from './trip_details'

export type TTProfileTripsResp = {
  history: TTripData[]
  active: TTripData[]
}

export enum TTripType {
  ACTIVE = 'active',
  HISTORY = 'history'
}

export type TTripData = {
  vehicle: TTripVehicle
  trip: TTripInfo
  host?: TCarHost
  payment?: TTripPayment
  contractUrl?: string | null
  hasRemainingPayment?: boolean
}

export type TTripVehicle = {
  id: number
  image: string
  longName: string
}

export type TTripInfo = {
  id: number
  hashedId: string
  hostname: string
  status: TTripStatus
  pickUp: TTripLocation
  dropOff: TTripLocation
}

type TTripStatus = {
  actions: string[]
  name: string
  color: string
  message: string
}

type TTripLocation = {
  date: string
  location: string
  shortDate: string
}
