import './main.css'

import '@app/services/error-tracing'

import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import Analytics from '@app/analytics/analytics'
import { GlobalProvider } from '@app/application/Global/GlobalContext'

import { router } from './pages/router'

void Analytics.init()

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <GlobalProvider>
      <RouterProvider router={router} />
    </GlobalProvider>
  </StrictMode>
)

if (import.meta.env.VITE_ENV !== 'development') {
  const script = document.createElement('script')
  script.src = '//code.jivosite.com/widget/BcbFGjYJWB'
  script.defer = true
  script.setAttribute('fetchpriority', 'low')
  document.body.appendChild(script)
}
