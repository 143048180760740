import './index.css'

import { Link } from 'react-router-dom'

import analyticEvents from '@app/analytics/events'
import { QueryVoccab, URLParamsFormatter } from '@app/infrastructure/url/QueryFormatter'
import LinkButton from '@shared/components/LinkButton/LinkButton'
import { AVAILABLE_NOW_MIN_RENT_DAYS } from '@shared/helpers/constants'
import dayjsExtended from '@shared/helpers/dayjsExtended'
import { CATALOG, LEARN_MORE } from '@shared/helpers/routes'

import { CelebritiesView } from './CelebritiesView'

export const Celebrities = () => {
  return (
    <section
      className="celebrities-cnt"
      id="about"
    >
      <div className="about-title-section">
        <h2 className="about-title">Your friends are already shopping with us</h2>
        <p className="about-description large">
          Leading rental car company in South Florida with all makes and models. We understand your
          wants and won&apos;t hold you back
        </p>
      </div>
      <div className="celebrities">
        <CelebritiesView />
      </div>
      <section className="about-subtitle-section">
        <Link
          to={LEARN_MORE}
          className="about-subtitle"
        >
          We get you
        </Link>
        <p className="about-description large">
          Our luxury performance cars give you the status you deserve. Show others what you are all
          about
        </p>
        <div className="about-button-wrapper">
          <LinkButton
            analyticId={analyticEvents.carAvailableNow}
            name="Cars available RIGHT NOW"
            to={URLParamsFormatter.formatPathParams(CATALOG(), {
              [QueryVoccab.pickUpTime]: dayjsExtended.utc().format(),
              [QueryVoccab.dropOffTime]: dayjsExtended
                .utc()
                .add(AVAILABLE_NOW_MIN_RENT_DAYS, 'days')
                .format()
            })}
            className="available-cars-link"
          />
        </div>
      </section>
    </section>
  )
}
