import './index.css'

import { FC } from 'react'

import LinkButton from '@shared/components/LinkButton/LinkButton'
import { HOME } from '@shared/helpers/routes'
import { Header } from '@shared/components'

type TBaseProps = {
  about: string
  backgroundClass: string
  cntClass: string
  buttonName: string
  title: string
  aboutClass?: string
  subtitle?: string
  subtitleClass?: string
}

export const Base: FC<TBaseProps> = ({
  title,
  subtitle,
  about,
  buttonName,
  backgroundClass,
  cntClass,
  subtitleClass,
  aboutClass
}) => {
  return (
    <>
      <article className="error-main-wrp">
        <Header />
        <section className={`error-content-cnt ${cntClass}`}>
          <div className="error-text-cnt">
            <h3 className="error-title">{title}</h3>
            <div className="error-description">
              <p className={subtitleClass}>{subtitle}</p>
              <p className={`error-about label-l ${aboutClass}`}>{about}</p>
            </div>
          </div>
          <div className={backgroundClass}></div>
          <LinkButton
            type="primary"
            to={HOME}
            className="error-button"
          >
            {buttonName}
          </LinkButton>
        </section>
      </article>
    </>
  )
}
