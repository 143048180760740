import '../index.css'

import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useShallow } from 'zustand/shallow'

import { TTProfileTripsResp } from '@api/types/profile/trips'
import { useProfileStore } from '@app/application/Authorized/ProfileStore'
import { useGet } from '@hooks/useApi'
import LinkButton from '@shared/components/LinkButton/LinkButton'
import { Loader } from '@shared/components/Loader/Loader'
import { ACTIVE_TRIPS, PROFILE_TRIPS, TRIP_HISTORY } from '@shared/helpers/routes'

const TRIP_LIST_LINKS = [
  {
    to: ACTIVE_TRIPS,
    name: 'Current trips'
  },
  {
    to: TRIP_HISTORY,
    name: 'History'
  }
]

const TripsLayout = () => {
  const location = useLocation()
  const profileStore = useProfileStore(
    useShallow(({ setTrips, trips }) => ({
      setTrips,
      trips
    }))
  )

  const { data, isLoading } = useGet<TTProfileTripsResp>({
    url: PROFILE_TRIPS,
    shouldCallRequestFn: () => Boolean(!profileStore.trips.length),
    deps: [profileStore.trips.length]
  })

  useEffect(() => {
    if (data) {
      profileStore.setTrips(data)
    }
  }, [data])

  return (
    <section className="trip-wrp">
      <nav className="trip-header">
        <ul className="trip-list-links">
          {TRIP_LIST_LINKS.map((link, index) => (
            <li key={index}>
              <LinkButton
                to={link.to}
                name={link.name}
                type={location.pathname.includes(link.to) ? 'primary' : 'secondary'}
              />
            </li>
          ))}
        </ul>
      </nav>
      <Loader loadingState={isLoading}>
        <Outlet />
      </Loader>
    </section>
  )
}

export default TripsLayout
