import './index.css'

import { FC } from 'react'

import { TCarHost } from '@api/types'
import { Avatar, Box, StarsRating } from '@shared/components'

type HostBlockProps = {
  host: TCarHost
}

export const HostBlock: FC<HostBlockProps> = ({ host }) => {
  return (
    <section>
      <h4 className="host-title">Host</h4>
      <div className="host-info">
        <Avatar
          alt={host.avatar ? "Host's avatar" : "Default host's avatar"}
          src={host.avatar}
        />
        <div className="host-details">
          <p className="medium">{host.name}</p>
          {host.phoneNumber && <p className="small">{host.phoneNumber}</p>}
          <StarsRating rating={4.5} />
          <Box gap="16px">
            <p className="host-detail label-s">{host.tripsCount}</p>
            <p className="host-detail label-s">Joined&nbsp;{host.joinedDate}</p>
          </Box>
        </div>
      </div>
      <p className="host-description label-l">{host.description}</p>
    </section>
  )
}
