import './index.css'

import { Base } from './Base'

export const Page500 = () => {
  return (
    <Base
      backgroundClass="page500-backImg"
      cntClass="page500-cnt"
      title="Internal server error"
      subtitle="Something went wrong"
      subtitleClass="page500-subtitle medium"
      about="We are sorry, this was unexpected :("
      aboutClass="page500-about"
      buttonName="Go to homepage"
    />
  )
}
