export const categories_data = {
  vehicleBrands: [
    {
      id: 21,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/021/thumb/34ece73c127ded5a4181810c919aeda9eb6e93e0.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 17,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/017/thumb/d685f6fb8401efb782c04b0ba301483d308d96ef.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 5,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/005/thumb/9d5f4fa32f18b2fb5951226e1041f87af44598f8.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 10,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/010/thumb/cd5a602d0b90d45f95f425a66d60f206071e40e5.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 9,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/009/thumb/b0727f583c4cbf069acb6f3a7ecc3a5ba9955877.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 2,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/002/thumb/d461aae1a55e0bfa94b2c71c9193b4e591807f93.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 24,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/024/thumb/afc6f28f8164427b5b43e95c3f8959c85c11932a.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 15,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/015/thumb/309b0c6dfc2d8f3593e23136d22a649fc1504d97.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 37,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/037/thumb/74ac082eeab237e855a6084d9dc962340989cc27.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 38,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/038/thumb/bc4dddf6dfcfb097db35795b0a68ed1b5645eebb.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 4,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/004/thumb/74cc64d7c9e16caaf76ddce54ae8c0188289003a.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 43,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/043/thumb/f97139286c93b139b6137e6828eed305952881a3.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 20,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/020/thumb/803e23811a458736247603059bad7deb66ac0495.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 39,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/039/thumb/ee0bd8c0831e9f17408a87f14756ab3daeb9eb1b.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 40,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/040/thumb/dd0fb3ec8881627baf52397e89e4f05ddf401780.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 18,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/018/thumb/8f6747486d8d6f173a9d0f414c8ae4792a277c3e.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 11,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/011/thumb/7a67f81d0415415575beacba1ff4ced599a0dc40.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 41,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/041/thumb/39c7fc9c9907d7ffcea0b00ed5d0386611bbb2de.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 26,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/026/thumb/353e2e44c87383cc7e3969f6c9fd4a79237a1c4c.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 3,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/003/thumb/c4a3428300469ac70162c6951bfdc1af57eaff0e.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 16,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/016/thumb/d828f2281467da57efe8799faed523ff6516b08c.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 19,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/019/thumb/f1c709571b8b922eb7d2db1c111f2fafb41dac39.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 14,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/014/thumb/d76cf6fdcaaf4f364ebe208723fe0a7fac14d2db.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 6,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/006/thumb/1fdfcee88e5f0364e789a44c17acda315f778628.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 8,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/008/thumb/68152e242cac8e68a9980144828b25c264288a5a.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 25,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/025/thumb/f2d9cdc3189e5a12b6cdb2b55cd03a4996725810.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 7,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/007/thumb/1fc8a3dfc01b478225af9d7b369e582f44a68dda.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 12,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/012/thumb/fa520d8f824b6a19985211b527fe74616b0ec558.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 1,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/001/thumb/10acda9b377df0a8400932053132e2614edab086.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 13,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/013/thumb/6d12a994286947ab3ffc28951660c8422911e3b0.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 42,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/042/thumb/5ef0a3c42124dd83c209de6f56e609913a71ce30.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    },
    {
      id: 22,
      iconUrl:
        'https://rentiplug-staging1.s3.amazonaws.com/vehicle_makers/icons/000/000/022/thumb/96846ea38e6a3ff4ccf34926dad1e67bad3dd051.jpg',
      sortIndex: 0,
      groupCode: 'brand'
    }
  ],
  categories: [
    {
      id: 96,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/012/original/7351943774d5d53d6f0926c20c260cb23f62557a.jpg',
      prestige: 'Newest',
      groupCode: 'newest',
      sortIndex: 0
    },
    {
      id: 98,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/010/original/fd889afd3a58787a6200793e48ca7d1536a33c1a.jpg',
      prestige: 'Luxury cars',
      groupCode: 'luxury',
      sortIndex: 0
    },
    {
      id: 100,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/008/original/ab72a86631a1c661aadf4a041b267a878275323e.jpg',
      prestige: "SUV's",
      groupCode: 'suv',
      sortIndex: 0
    },
    {
      id: 103,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/005/original/9d7225e1571d023d3b8a712e16ec3130be316a40.jpg',
      prestige: 'Corvertible',
      groupCode: 'corvertible',
      sortIndex: 0
    },
    {
      id: 105,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/003/original/c01367d39e1d47d717be537f0868617e680f4b8f.jpg',
      prestige: 'Sedan',
      groupCode: 'sedan',
      sortIndex: 0
    },
    {
      id: 106,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/002/original/f4214d319ea5f5465501561a7ce8150a7c402b08.jpg',
      prestige: 'Standart',
      groupCode: 'standart',
      sortIndex: 0
    },
    {
      id: 104,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/004/original/63b160d2d1dc13363978e16639844e8592c50c83.jpg',
      prestige: 'Sport cars',
      groupCode: 'sport',
      sortIndex: 0
    },
    {
      id: 102,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/006/original/2e77475bd2ec311d115f7b2e4b0c185427daffd9.jpg',
      prestige: 'Supercars',
      groupCode: 'supercar',
      sortIndex: 0
    },
    {
      id: 101,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/007/original/a9c46ce9df64c2bdd24a15dbb720ae5079299978.jpg',
      prestige: 'Business cars',
      groupCode: 'business',
      sortIndex: 0
    },
    {
      id: 99,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/009/original/50f93ef5eb26f7d4577abb50719aaf77c960da88.jpg',
      prestige: 'Lowkeys',
      groupCode: 'lowkey',
      sortIndex: 0
    },
    {
      id: 97,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/011/original/4bfad531ceccf2c34a6e3f6e7c79528e3f974250.jpg',
      prestige: 'Strikers',
      groupCode: 'striker',
      sortIndex: 0
    },
    {
      id: 107,
      src: 'https://rentiplug-staging1.s3.amazonaws.com/common_reference_book_detail_media_files/common_reference_book_detail_media/000/000/014/original/56d5fec9d6c564bbf5de60789548915332ecbfa7.jpg',
      prestige: 'Coupe',
      groupCode: 'coupe',
      sortIndex: 0
    }
  ]
}
