import { TTripData } from '@api/types/profile/trips'
import { useProfileStore } from './ProfileStore'

class TripService {
  private static instance: TripService
  private store: typeof useProfileStore

  private constructor(store: typeof useProfileStore) {
    this.store = store
  }

  public static getInstance(store = useProfileStore): TripService {
    if (!TripService.instance) {
      TripService.instance = new TripService(store)
    }
    return TripService.instance
  }

  public getTripById(id: number): TTripData | null {
    const { trips } = this.store.getState()
    const foundTrip = trips.find((item) => item.tripData.trip.id === id)

    return foundTrip ? foundTrip.tripData : null
  }
}

const tripConstructor = TripService.getInstance()
export default tripConstructor
