export const CelebritiesView = () => {
  return (
    <>
      <div className="celebrity-cnt">
        <img
          className="styled-image"
          src="./images/home/celebrity1.webp"
          alt="Celebrity at night with car in the background"
        />
      </div>
      <div className="celebrity-cnt">
        <img
          className="styled-image"
          src="./images/home/celebrity2.webp"
          alt="Two celebrities sitting on a car with black and white background"
        />
      </div>
      <div className="celebrity-cnt">
        <img
          className="styled-image"
          src="./images/home/celebrity3.webp"
          alt="Celebrity sitting in a car with open doors"
        />
      </div>
      <div className="celebrity-cnt">
        <img
          className="styled-image"
          src="./images/home/celebrity4.webp"
          alt="Celebrity pouring champagne with car in the background"
        />
      </div>
      <div className="celebrity-cnt">
        <img
          className="styled-image"
          src="./images/home/celebrity5.webp"
          alt="Celebrity in the city with car in the background"
        />
      </div>
      <div className="celebrity-cnt">
        <img
          className="styled-image"
          src="./images/home/celebrity6.webp"
          alt="Celebrity with money in front of a car"
        />
      </div>
    </>
  )
}
