import './index.css'

import { FC, useRef } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { TVehicleCategoriesResp } from '@api/types'
import {
  QuerySuffixVoccab,
  QueryVoccab,
  URLParamsFormatter
} from '@app/infrastructure/url/QueryFormatter'
import useDragHorizontal from '@hooks/useDragHorizontal'
import PopularCars from '@shared/components/PopularCars/PopularCars'
import { timestamp } from '@shared/helpers/helpers'
import { CATALOG } from '@shared/helpers/routes'

import { categories_data } from './data'

const VEHICLE_MANUFACTURER_KEY = 'car_maker'
const MULTIPLE_FILTER_TYPE = 'multiple'

type TOurWhipssProps = {
  ourFleet?: TVehicleCategoriesResp
}

export const OurWhipss: FC<TOurWhipssProps> = ({ ourFleet = {} }) => {
  const [, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const categoriesListRef = useRef<HTMLUListElement | null>(null)
  const brandsListRef = useRef<HTMLUListElement | null>(null)

  const dragHandlersForCategories = useDragHorizontal(categoriesListRef)
  const dragHandlersForBrands = useDragHorizontal(brandsListRef)

  const handleSelectModel = (selectedValue: number) => {
    const formattedParams = URLParamsFormatter.formatDynamicObjectParams({
      storeKey: QueryVoccab.filters,
      dynamicKey: VEHICLE_MANUFACTURER_KEY,
      value: [selectedValue],
      other: {
        [URLParamsFormatter.formatDynamicObjectServiceField(
          VEHICLE_MANUFACTURER_KEY,
          QuerySuffixVoccab.filterType
        )]: MULTIPLE_FILTER_TYPE,
        [QueryVoccab.searchId]: timestamp()
      }
    })

    setSearchParams(formattedParams)
    navigate(URLParamsFormatter.formatPathParams(CATALOG()))
  }

  return (
    <section
      className="our-whipss"
      id="fleet"
    >
      <div className="whipss-categories">
        <h2 className="our-whipss-title">Our whipss</h2>
        {ourFleet && (
          <>
            <ul
              aria-label="Scrollable list of vehicle categories"
              role="listbox"
              className="whipss-items scrollable-cnt"
              ref={categoriesListRef}
              {...dragHandlersForCategories}
            >
              {categories_data.categories?.map((category) => (
                <li
                  className="whipss-item"
                  key={category.id}
                  draggable={false}
                >
                  <Link
                    draggable={false}
                    to={URLParamsFormatter.formatPathParams(CATALOG(), {
                      [QueryVoccab.categoryId]: category.id
                    })}
                  >
                    <img
                      loading="lazy"
                      fetchPriority="low"
                      className="whipss-img"
                      draggable={false}
                      src={category.src}
                      alt={category.prestige}
                    />
                  </Link>
                  <p className="small">{category.prestige}</p>
                </li>
              ))}
            </ul>
            <ul
              aria-label="Scrollable list of vehicle brands"
              role="listbox"
              className="models scrollable-cnt"
              ref={brandsListRef}
              {...dragHandlersForBrands}
            >
              {categories_data.vehicleBrands?.map((model) => (
                <li
                  key={model.id}
                  draggable={false}
                >
                  <button
                    className="model-link"
                    draggable={false}
                    onClick={() => handleSelectModel(model.id)}
                  >
                    <img
                      src={model.iconUrl}
                      alt="model"
                      draggable={false}
                      loading="lazy"
                      fetchPriority="low"
                      width="40px"
                      height="40px"
                    />
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <section className="popular-cars-cnt">
        <h4 className="popular-title">Hot right now</h4>
        <PopularCars />
      </section>
    </section>
  )
}
