import { wrapCreateBrowserRouterV6 } from '@sentry/react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

import Layout from '@shared/components/Layout/Layout'
import lazyRoute from '@shared/helpers/asyncRouteLoader'
import {
  ABOUT_US,
  ACTIVE_TRIPS,
  BECOME_HOST,
  CAR,
  CATALOG,
  CHECKOUT,
  CONTACT_US,
  COOKIE,
  EDIT_PROFILE,
  HOME,
  INBOX,
  INTERNAL_SERVER_ERROR,
  LEARN_MORE,
  LEGAL,
  NOT_FOUND,
  PAYMENT_OPTIONS,
  PRIVACY,
  PROFILE,
  PROHIBITED,
  RENTAL,
  SIGN_IN,
  SIGN_UP,
  TERMS,
  TRIP,
  TRIP_HISTORY
} from '@shared/helpers/routes'

import { TripDetailsContainer } from '../authorized/pages/MyTrips/TripDetails/TripDetailsContainer'
import { ActiveTrips } from '../authorized/pages/MyTrips/TripList/ActiveTrips'
import { TripHistory } from '../authorized/pages/MyTrips/TripList/TripHistory'
import TripsLayout from '../authorized/pages/MyTrips/TripList/TripsLayout'
import { Menu } from '../authorized/pages/ProfileMenu/Menu'
import { SignIn } from './Authorization/SignIn'
import { SignUp } from './Authorization/SignUp'
import { HomeContainer } from './Home/HomeContainer'
import { HowToPay } from './Payment/HowToPay/HowToPay'
import { Page404 } from './StatusCodes/Page404'
import { Page500 } from './StatusCodes/Page500'
import { LegalMatters } from './TermAndConditions/LegalMatters'

const sentryCreateBrowserRouter = wrapCreateBrowserRouterV6(createBrowserRouter)
export const router = sentryCreateBrowserRouter([
  {
    element: <Layout />,
    children: [
      // ********* unauthorized routes ************
      // service routes
      {
        path: INTERNAL_SERVER_ERROR,
        Component: Page500
      },
      {
        path: NOT_FOUND,
        Component: Page404
      },
      // actual app routes
      {
        path: SIGN_UP,
        Component: SignUp
      },
      {
        path: SIGN_IN,
        Component: SignIn
      },
      {
        path: HOME,
        Component: HomeContainer
      },
      {
        path: CATALOG(),
        lazy: lazyRoute(() => import('./Catalog/CatalogContainer'), 'CatalogContainer')
      },
      {
        path: CAR(':id'),
        lazy: lazyRoute(() => import('./CarDetails/CarDetailsContainer'), 'CarDetailsContainer')
      },
      {
        path: CAR(':id/rentals/*'),
        lazy: lazyRoute(() => import('./Steps/StepsContainer'), 'StepsContainer')
      },
      {
        path: ABOUT_US,
        lazy: lazyRoute(() => import('./AboutUs/AboutUs'), 'AboutUs')
      },
      {
        path: LEARN_MORE,
        lazy: lazyRoute(() => import('./LearnMore/LearnMore'), 'LearnMore')
      },
      {
        path: LEGAL,
        Component: LegalMatters,
        children: [
          {
            path: TERMS,
            lazy: lazyRoute(() => import('./TermAndConditions/Terms'), 'Terms')
          },
          {
            path: PROHIBITED,
            lazy: lazyRoute(() => import('./TermAndConditions/Prohibited'), 'Prohibited')
          },
          {
            path: PRIVACY,
            lazy: lazyRoute(() => import('./TermAndConditions/Privacy'), 'Privacy')
          },
          {
            path: RENTAL,
            lazy: lazyRoute(() => import('./TermAndConditions/Rental'), 'Rental')
          },
          {
            path: COOKIE,
            lazy: lazyRoute(() => import('./TermAndConditions/Cookie'), 'Cookie')
          }
        ]
      },
      {
        path: CONTACT_US,
        lazy: lazyRoute(() => import('./ContactUs/ContactUsContainer'), 'ContactUsContainer')
      },
      {
        path: PAYMENT_OPTIONS,
        Component: HowToPay
      },
      {
        path: CHECKOUT,
        lazy: lazyRoute(() => import('./Payment/Checkout/Checkout'), 'Checkout')
      },
      {
        path: BECOME_HOST,
        lazy: lazyRoute(() => import('./BecomeHost/BecomeHostContainer'), 'BecomeHostContainer')
      },
      // ********* 🛑🛑🛑 AUTHORIZED routes 🛑🛑🛑 ************
      {
        path: PROFILE(),
        Component: Menu,
        children: [
          {
            path: 'trips',
            Component: TripsLayout,
            children: [
              {
                index: true,
                element: (
                  <Navigate
                    to={ACTIVE_TRIPS}
                    replace
                  />
                )
              },
              {
                path: ACTIVE_TRIPS,
                Component: ActiveTrips
              },
              {
                path: TRIP_HISTORY,
                Component: TripHistory
              }
            ]
          },
          {
            path: TRIP(':id'),
            Component: TripDetailsContainer
          },
          {
            path: INBOX,
            lazy: lazyRoute(() => import('../authorized/pages/Chat/Chat'), 'Chat')
          },
          {
            path: EDIT_PROFILE,
            lazy: lazyRoute(() => import('../authorized/pages/Settings/Settings'), 'Settings')
          }
        ]
      },
      // ********* ALWAYS goes last! ************
      {
        path: '/*',
        element: <Navigate to={NOT_FOUND} />
      }
    ]
  }
])
