import './index.css'

import { Base } from './Base'

export const Page404 = () => {
  return (
    <Base
      backgroundClass="page404-backImg"
      cntClass="page404-cnt"
      title="Page not found"
      about="Use menu to find what you are looking for, or go back to the home page"
      buttonName="Go to homepage"
    />
  )
}
