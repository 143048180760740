import {
  ApplePay,
  BitcoinATM,
  Cards,
  Cash,
  CashApp,
  GiftCards,
  GooglePay,
  Paypal,
  Venmo
} from '@shared/icons'

export const paymentOptions = [
  {
    id: 1,
    icon: Cash,
    name: 'Cash',
    method: 'cash',
    disabled: false
  },
  {
    id: 2,
    icon: CashApp,
    name: 'Cash App',
    method: 'cash-app',
    disabled: false
  },
  {
    id: 3,
    icon: Cards,
    name: 'Cards',
    method: 'card',
    disabled: false
  },
  {
    id: 4,
    icon: ApplePay,
    name: 'Apple Pay',
    method: 'apple-pay',
    disabled: false
  },
  {
    id: 5,
    icon: GooglePay,
    name: 'Google Pay',
    method: 'google-pay',
    disabled: false
  },
  {
    id: 6,
    icon: BitcoinATM,
    name: 'Bitcoin ATM',
    soon: 'Will be available soon',
    method: 'crypto',
    disabled: true
  },
  {
    id: 7,
    icon: GiftCards,
    name: 'Gift cards',
    soon: 'Will be available soon',
    method: null,
    disabled: true
  },
  {
    id: 8,
    icon: Paypal,
    name: 'Paypal',
    soon: 'Will be available soon',
    method: null,
    disabled: true
  },
  {
    id: 9,
    icon: Venmo,
    name: 'Venmo',
    soon: 'Will be available soon',
    method: null,
    disabled: true
  }
]
