import './index.css'

import { Accordion } from '@shared/components'

const faqList = [
  {
    id: 1,
    question: 'What are the requirements to rent?',
    answer: 'You need to have a valid license and be over 18 y/o'
  },
  {
    id: 2,
    question: 'What is your security deposit?',
    answer: 'Security deposit varies from $250 to $1000 depending on number of days and whip you choose.'
  },
  {
    id: 3,
    question: 'Can I extend the rental?',
    answer: 'You can always extend the rental as long as it not reserved already'
  },
  {
    id: 4,
    question: 'What are the mileage restrictions?',
    answer: 'You get 150 free miles per day, you can buy unlimited miles at checkout'
  },
  {
    id: 5,
    question: 'Do you require insurance on rentals?',
    answer: 'We only require insurance on exotic whipss'
  },
  {
    id: 6,
    question: 'Can I give the rental to my friend?',
    answer: 'Anyone driving needs to be added on the rental agreement to ensure the safety of renter'
  }
]

const FAQ = () => {
  return (
    <section
      className="faq-section"
      id="faq"
    >
      <h2 className="faq-title">FAQ</h2>
      <div className="accordion-container">
        {faqList.map((faq) => (
          <div key={faq.id}>
            <Accordion summary={faq.question}>
              <p className="faq-answer label-l">{faq.answer}</p>
            </Accordion>
          </div>
        ))}
      </div>
    </section>
  )
}

export default FAQ
