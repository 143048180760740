import './index.css'

const stepsList = [
  {
    id: 1,
    description: 'Choose your whip'
  },
  {
    id: 2,
    description: 'Reserve online'
  },
  {
    id: 3,
    description: 'Get your whip'
  },
  {
    id: 4,
    description: 'Return or extend'
  }
]

const Steps = () => {
  return (
    <section className="steps-section">
      <div className="steps-title-container">
        <div>
          <h2 className="steps-title">How it works</h2>
          <p className="steps-subtitle large">
            We have an easy booking process that allows you to get the whip you want fast.
          </p>
        </div>
        <div className="steps-img">
          <img
            fetchPriority="low"
            loading="lazy"
            src="./images/home/stepsImg.webp"
            alt="img.png"
          />
        </div>
      </div>
      <ul className="steps-container">
        {stepsList?.map((step) => (
          <li
            className="step"
            key={step.id}
          >
            <h6 className="steps-number">{step.id}</h6>
            <h6 className="steps-about">{step.description}</h6>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default Steps
