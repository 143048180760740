import './index.css'

import { useNavigate } from 'react-router-dom'

import { URLParamsFormatter } from '@app/infrastructure/url/QueryFormatter'
import { BookingContainer, Header } from '@shared/components'
import { CATALOG } from '@shared/helpers/routes'

import { Celebrities } from './components/Celebrities/Celebrities'
import FAQ from './components/FAQ/FAQ'
import { OurWhipss } from './components/OurWhipss/OurWhipss'
import Steps from './components/Steps/Steps'

export const HomeContainer = () => {
  const navigate = useNavigate()

  const handleLocationSearch = () => {
    navigate(URLParamsFormatter.formatPathParams(CATALOG()))
  }

  return (
    <>
      <article className="home-cnt">
        <div className="main-wrapper">
          <Header />
          <div className="pickup-wrapper">
            <h1 className="home-title">Car rental</h1>
            <BookingContainer
              forceContainerView
              onSearch={handleLocationSearch}
            />
          </div>
        </div>
        <Celebrities />
        <OurWhipss
        // ourFleet={ourFleet}
        />
        <Steps />
        <FAQ />
      </article>
    </>
  )
}
