import '../index.css'

import { FC } from 'react'
import cn from 'classnames'

import { TPaymentMethods } from '@api/types/trips/payment'

import { paymentOptions } from '../data'

type PaymentOptionsProps = {
  direction?: 'row' | 'column'
  onSubmit?: () => Promise<void>
  onClick?: (val: TPaymentMethods | null) => void | null
}

export const PaymentOptions: FC<PaymentOptionsProps> = ({
  direction = 'column',
  onClick = null,
  onSubmit = null
}) => {
  return (
    <form
      className={cn('payment-options-cnt', {
        'grid-row': direction === 'row'
      })}
      onSubmit={onSubmit ?? (() => { })}
    >
      {paymentOptions.map((option) => (
        <button
          key={option.id}
          disabled={option.disabled}
          className={cn('payment-option-btn', {
            'grid-row': direction === 'row',
            clickable: Boolean(onClick)
          })}
          onClick={() => {
            if (onClick) onClick(option.method)
          }}
          type="submit"
        >
          <span className="payment-options-icon">
            <option.icon />
          </span>
          <span className="payment-options-info">
            <h5
              className={cn('payment-options-name', {
                'payment-options-soon': option.soon
              })}
            >
              {option.name}
            </h5>
            {option.soon && <p className="payment-options-soon label-s">{option.soon}</p>}
          </span>
        </button>
      ))}
    </form>
  )
}
