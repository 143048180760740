import cn from 'classnames'
import { Outlet, useLocation } from 'react-router-dom'
import './index.css'

import LinkButton from '@shared/components/LinkButton/LinkButton'
import { CONTACT_US, PROFILE_TRIPS, TRIP } from '@shared/helpers/routes'
import { Contacts, Traffic } from '@shared/icons'

export const profileMenuItems = [
  {
    to: PROFILE_TRIPS,
    text: 'My trips',
    icon: <Traffic />
  },
  // {
  //   to: PROFILE(`/${INBOX}`),
  //   text: 'Inbox',
  //   icon: <Mail />
  // },
  // {
  //   to: PROFILE(`/${EDIT_PROFILE}`),
  //   text: 'Edit profile',
  //   icon: <Settings />
  // }
  {
    to: CONTACT_US,
    text: 'Contact Us',
    icon: <Contacts />
  }
]

export const Menu = () => {
  const location = useLocation()
  const tripDetailPage = location.pathname.startsWith(TRIP())

  return (
    <main className={cn('profile', { '--single-page': tripDetailPage })}>
      <aside className={cn('profile-menu', { '--single-page': tripDetailPage })}>
        <nav className="profile-menu-tabs">
          <ul>
            {profileMenuItems.map(({ to, text, icon }, index) => (
              <li key={index}>
                <LinkButton
                  to={to}
                  className={cn('profile-menu-tab', {
                    active:
                      location.pathname.startsWith(to) || (to === PROFILE_TRIPS && tripDetailPage)
                  })}
                  type=""
                >
                  {icon}
                  {text}
                </LinkButton>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
      <Outlet />
    </main>
  )
}
