import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

import { version } from '../../../../package.json'

init({
  dsn: import.meta.env.VITE_SENTRY_DSN as string,
  enabled: import.meta.env.VITE_ENV !== 'development',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
    // replayIntegration()
  ],
  release: version,
  environment: import.meta.env.VITE_ENV as string,
  autoSessionTracking: false,
  beforeSend(event) {
    return event
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 0.25
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  // replaysSessionSampleRate: 0,
  // replaysOnErrorSampleRate: 0
})
