import '../index.css'

import { FC } from 'react'

import { TTripPayment } from '@api/types/profile/trip_details'
import CurrencyFormatter from '@shared/helpers/currencyFormatter'

type TripPaymentInfoProps = {
  payment: TTripPayment
}

export const TripPaymentInfo: FC<TripPaymentInfoProps> = ({ payment }) => {
  return (
    <section className="trip-payment-cnt">
      <p className="medium trip-payment-item">
        <span>Total</span>
        <span>
          {CurrencyFormatter.format({
            amount: payment.total ?? 0
          })}
        </span>
      </p>
      <p className="medium trip-payment-item">
        <span>Security deposit</span>
        <span>
          {CurrencyFormatter.format({
            amount: payment.securityDeposit ?? 0
          })}
        </span>
      </p>
      <p className="medium trip-payment-item">
        <span>Paid</span>
        <span>
          {CurrencyFormatter.format({
            amount: payment.paid ?? 0
          })}
        </span>
      </p>
      {payment.remaining != null && payment.remaining > 0 && (
        <p className="medium trip-payment-item">
          <span>Remaining to Pay</span>
          <span>
            {CurrencyFormatter.format({
              amount: payment.remaining
            })}
          </span>
        </p>
      )}
    </section>
  )
}
