import '../index.css'

import analyticEvents from '@app/analytics/events'
import ExternalLink from '@shared/components/ExternalLink/ExternalLink'

import { PaymentOptions } from '../components/PaymentOptions'

export const HowToPay = () => {
  return (
    <article className="payment-options-wrp">
      <section className="payment-options-about">
        <h3 className="payment-title">Payment Options - Pay the Way You Want</h3>
        <p className="payment-options-subtitle">
          Select the whip you want to ride at&nbsp;
          <ExternalLink
            href="https://whipss.com"
            analyticId={analyticEvents.homePage}
          >
            https://whipss.com
          </ExternalLink>
          &nbsp;and make a quote. In booking confirmation you will get requisites for payment for
          mentioned payment methods.
        </p>
      </section>
      <PaymentOptions />
    </article>
  )
}
