import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useShallow } from 'zustand/shallow'

import { TTripDetailsDataResp } from '@api/types/profile/trip_details'
import { TTripType } from '@api/types/profile/trips'
import { useProfileStore } from '@app/application/Authorized/ProfileStore'
import TripService from '@app/application/Authorized/TripService'
import { URLParamsFormatter } from '@app/infrastructure/url/QueryFormatter'
import { useGet } from '@hooks/useApi'
import { PROFILE_TRIPS, TRIP_HISTORY } from '@shared/helpers/routes'
import { TripDetails } from './components/TripDetails'

export const TripDetailsContainer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams<{ id: string }>()
  const [isContractReady, setIsContractReady] = useState(false)

  const { data } = useGet<TTripDetailsDataResp>({
    url: `${PROFILE_TRIPS}/${id}`
  })

  const profileStore = useProfileStore(
    useShallow(({ updateSelectedTrip, setSingleTrip, singleTrip }) => ({
      updateSelectedTrip,
      setSingleTrip,
      singleTrip
    }))
  )

  const handleNavigateBack = () => {
    const fromPage = location.state?.from
    if (fromPage === TTripType.HISTORY) {
      navigate(URLParamsFormatter.formatPathParams(`${PROFILE_TRIPS}/${TRIP_HISTORY}`))
    } else {
      navigate(URLParamsFormatter.formatPathParams(PROFILE_TRIPS))
    }
  }

  useEffect(() => {
    if (!id) return handleNavigateBack()
    if (data) {
      profileStore.updateSelectedTrip(data)
      profileStore.setSingleTrip(data)
    }
  }, [data, id])

  const singleTrip = TripService.getTripById(Number(id)) ?? profileStore.singleTrip

  return (
    <>
      {!singleTrip ? (
        <h4 className="loading-message">Loading...</h4>
      ) : (
        <TripDetails
          setIsContractReady={setIsContractReady}
          isContractReady={isContractReady}
          singleTrip={singleTrip}
          handleNavigateBack={handleNavigateBack}
        />
      )}
    </>
  )
}
