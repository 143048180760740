import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { URLParamsFormatter } from '@app/infrastructure/url/QueryFormatter'
import { Button } from '@shared/components'
import { HOME } from '@shared/helpers/routes'

type TActionType =
  | 'new-trip'
  | 'pay'
  | 'confirm-pick-up'
  | 'confirm-drop-off'
  | 'end'
  | 'extension'
  | 'rate'

type TTripActionButtonsProps = {
  actions: string[]
  tripId: string
}

export const TripActionButtons: FC<TTripActionButtonsProps> = ({ actions, tripId }) => {
  const navigate = useNavigate()

  const actionConfig: Record<TActionType, { name: string; action: () => void }> = {
    'new-trip': {
      name: 'New Trip',
      action: () => navigate(URLParamsFormatter.formatPathParams(HOME))
    },
    pay: {
      name: 'Pay Now',
      action: () => navigate(URLParamsFormatter.formatPathParams(`/checkout/${tripId}`))
    },
    'confirm-pick-up': {
      name: 'Confirm Pick-Up',
      action: () => navigate(URLParamsFormatter.formatPathParams(HOME))
    },
    'confirm-drop-off': {
      name: 'Confirm Drop-Off',
      action: () => navigate(URLParamsFormatter.formatPathParams(HOME))
    },
    end: {
      name: 'End Trip',
      action: () => navigate(URLParamsFormatter.formatPathParams(HOME))
    },
    extension: {
      name: 'Extend trip',
      action: () => navigate(URLParamsFormatter.formatPathParams(HOME))
    },
    rate: {
      name: 'Rate your trip',
      action: () => navigate(URLParamsFormatter.formatPathParams(HOME))
    }
  }

  const excludedActions: TActionType[] = [
    'rate',
    'confirm-pick-up',
    'confirm-drop-off',
    'end',
    'extension'
  ]
  const filteredActions = actions?.filter(
    (action) => !excludedActions.includes(action as TActionType)
  )

  if (!filteredActions || filteredActions.length === 0) {
    return null
  }

  if (filteredActions.length === 1) {
    const action = filteredActions[0] as TActionType

    return (
      <Button
        name={actionConfig[action].name}
        onClick={actionConfig[action].action}
        variant="secondary"
      />
    )
  }

  return (
    <div className="details-trip-buttons">
      {actions.map((action, index) => (
        <Button
          key={index}
          name={actionConfig[action as TActionType].name}
          onClick={actionConfig[action as TActionType].action}
          variant="secondary"
        />
      ))}
    </div>
  )
}
