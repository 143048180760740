import { TTripType } from '@api/types/profile/trips'
import { useProfileStore } from '@app/application/Authorized/ProfileStore'
import { TripList } from './TripList'

export const ActiveTrips = () => {
  const storedTrips = useProfileStore((state) => state.trips)
  const activeTrips = storedTrips
    .filter((trip) => trip.type === TTripType.ACTIVE)
    .map((item) => item.tripData)

  return <TripList trips={activeTrips} />
}
