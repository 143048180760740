import '../index.css'

import { FC } from 'react'

import { TTripData } from '@api/types/profile/trips'
import { URLParamsFormatter } from '@app/infrastructure/url/QueryFormatter'
import { Accordion, Button, RentalDateLocation } from '@shared/components'
import LinkButton from '@shared/components/LinkButton/LinkButton'
import { CaretLeft } from '@shared/icons'
import { useNavigate } from 'react-router-dom'
import { HostBlock } from '../../../../../pages/CarDetails/components/HostBlock/HostBlock'
import { TripActionButtons } from './TripActionButtons'
import { TripPaymentInfo } from './TripPaymentInfo'

const BUTTON_STATUS = ['booked', 'rental', 'completed']

type TTRipDetailsProps = {
  singleTrip: TTripData
  handleNavigateBack: () => void
  isContractReady: boolean
  setIsContractReady: (event: boolean) => void
}

export const TripDetails: FC<TTRipDetailsProps> = ({
  singleTrip,
  handleNavigateBack,
  isContractReady,
  setIsContractReady
}) => {
  const navigate = useNavigate()

  const { trip, vehicle, payment, host, contractUrl, hasRemainingPayment } = singleTrip
  const currentTripStatus = trip.status.name.toLowerCase()
  const displayButtons = BUTTON_STATUS.some((status) => status === currentTripStatus)

  return (
    <main className="details-wrp">
      <header className="details-top">
        <CaretLeft
          width="32"
          height="32"
          onClick={handleNavigateBack}
        />
        <h4>Trip #{trip.id}</h4>
      </header>
      {displayButtons && (
        <section className="details-top-buttons">
          <LinkButton
            name="Details"
            to=""
            type="primary"
            className="details-top-button"
          />
          {/* <LinkButton
            name="Messages"
            to={PROFILE(`/${INBOX}`)}
            className="details-top-button"
          /> */}
        </section>
      )}
      <section className="details-info">
        <div className="details-info-cnt">
          <img
            className="details-photo"
            src={vehicle.image}
            alt="The car used during the trip"
          />
          <p className="label-bold-m">{vehicle.longName}</p>
          <p
            className="trip-status small"
            style={{
              color: trip.status.color,
              background: `color-mix(in srgb, ${trip.status.color}, white 90%)`
            }}
          >
            {trip.status.name}
          </p>
        </div>
        <div className="details-description-cnt">
          <p className="details-description label-l">{trip.status.message}</p>
          <TripActionButtons
            actions={trip.status.actions}
            tripId={trip.hashedId}
          />
        </div>
      </section>
      <RentalDateLocation
        pickupDate={trip.pickUp.date}
        returnDate={trip.dropOff.date}
        pickupLocation={trip.pickUp.location}
        returnLocation={trip.dropOff.location}
      />

      {payment?.total && <TripPaymentInfo payment={payment} />}
      {hasRemainingPayment && (
        <div className="details-button pay-now">
          <Button
            onClick={() =>
              navigate(URLParamsFormatter.formatPathParams(`/checkout/${trip.hashedId}`))
            }
            name="Pay now"
            variant="secondary"
          />
        </div>
      )}
      {contractUrl && (
        <Accordion
          size="small"
          summary="Your contract"
        >
          <>
            {!isContractReady && (
              <h4 className="loading-message">
                We're generating the contract for you. Please wait...
              </h4>
            )}
            <iframe
              width="100%"
              height="80%"
              style={{ border: 'none' }}
              src={contractUrl}
              allowFullScreen
              onLoad={() => setIsContractReady(true)}
              loading="lazy"
            />
          </>
        </Accordion>
      )}
      {host?.name && <HostBlock host={host} />}
    </main>
  )
}
