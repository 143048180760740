import '../index.css'

import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { TTripData } from '@api/types/profile/trips'
import { ACTIVE_TRIPS, TRIP, TRIP_HISTORY } from '@shared/helpers/routes'

type TripListProps = {
  trips: TTripData[]
}

export const TripList: FC<TripListProps> = ({ trips }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const fromPage = location.pathname.includes(TRIP_HISTORY) ? TRIP_HISTORY : ACTIVE_TRIPS

  return (
    <>
      {trips.length > 0 ? (
        <ul className="trips-list">
          {trips.map((item) => {
            return (
              <li key={item.trip.id}>
                <button
                  className="trip-btn"
                  onClick={() => navigate(TRIP(item.trip.id), { state: { from: fromPage } })}
                >
                  <img
                    src={item.vehicle.image ?? ''}
                    alt="The car used during the trip"
                    className="trip-image"
                  />
                  <div className="trip-info">
                    <div className="trip-info-cnt">
                      <p className="label-m">
                        {item.trip.pickUp.shortDate} - {item.trip.dropOff.shortDate}
                      </p>
                      <p
                        className="trip-status"
                        style={{
                          color: item.trip.status.color,
                          background: `color-mix(in srgb, ${item.trip.status.color}, white 90%)`
                        }}
                      >
                        {item.trip.status.name}
                      </p>
                    </div>
                    <h6>{item.vehicle.longName}</h6>
                    <p className="label-m">Trip #{item.trip.id}</p>
                    <p className="label-m">{item.trip.hostname}</p>
                  </div>
                </button>
              </li>
            )
          })}
        </ul>
      ) : (
        <h4 className="loading-message">You don't have trips yet</h4>
      )}
    </>
  )
}
