import './index.css'

import { yupResolver } from '@hookform/resolvers/yup'
import { captureMessage } from '@sentry/react'
import cn from 'classnames'
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { object, string } from 'yup'

import { TAllowedMethods } from '@api/api'
import { TAuthVerificationResp } from '@api/types'
import { TAuthVerificationCodeResp } from '@api/types/auth/verification_code'
import { apiV1 } from '@api/v1'
import analyticEvents from '@app/analytics/events'
import AuthorizationService from '@app/application/Authorization/Authorization'
import { useFormFieldValidator } from '@hooks/useFormFieldValidator'
import { Box, Button, Checkbox, FormInput } from '@shared/components'
import ExternalLink from '@shared/components/ExternalLink/ExternalLink'
import { getCountryCode } from '@shared/helpers/helpers'
import {
  API_AUTH_VERIFICATION,
  API_AUTH_VERIFICATION_CODE,
  LEGAL,
  PRIVACY,
  PROFILE_TRIPS,
  SIGN_IN,
  SIGN_UP
} from '@shared/helpers/routes'

export type TAuthorizationFormValues = {
  phone: string
}
type AuthorizationFormProps = {
  isLogin: boolean
}

const authorizationFormSchema = object().shape({
  phone: string()
    .test('is-valid-phone', 'Phone number must contain 10 digits', (value) => {
      const digitsOnly = value ? value.replace(/\s/g, '') : ''
      return digitsOnly.length === 10 && /^\d+$/.test(digitsOnly)
    })
    .required('This field is required')
})
const OTP_LENGTH = 6
const OTP_ARRAY = Array(OTP_LENGTH).fill(null)
const DEFAULT_VALUES = {
  isPolicyAccepted: false,
  checkOTP: false,
  phone: '',
  otpCode: ''
}
const PHONE_NUMBER_LENGTH = 10

export const AuthorizationForm: FC<AuthorizationFormProps> = ({ isLogin }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [state, setState] = useState(DEFAULT_VALUES)

  const phoneInputRef = useRef<HTMLInputElement | null>(null)
  const otpInputRef = useRef<HTMLInputElement | null>(null)

  const { isPolicyAccepted, checkOTP, phone, otpCode } = state
  const title = isLogin ? 'Log in to your account' : 'Sign up'
  const subtitle = isLogin ? 'Don’t have a Whip$$ account?' : 'Already have a Whip$$ account?'

  const {
    register,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<TAuthorizationFormValues>({
    resolver: yupResolver(authorizationFormSchema),
    defaultValues: DEFAULT_VALUES
  })
  const { handleFieldChange } = useFormFieldValidator<TAuthorizationFormValues>({
    schema: authorizationFormSchema,
    setFormData: setState,
    clearErrors,
    setError,
    clearErrorMessage: () => setErrorMessage('')
  })

  useEffect(() => {
    if (checkOTP && otpInputRef.current) {
      otpInputRef.current.focus()
    }
  }, [checkOTP])

  const sendAuthRequest = async (
    url: string,
    payload: Record<string, TAuthVerificationResp | TAuthVerificationCodeResp>,
    onSuccess?: (val: unknown) => void
  ) => {
    setIsLoading(true)

    try {
      const result = await apiV1.requestHandler(url, TAllowedMethods.POST, payload)
      if (result.error) {
        setErrorMessage('Something went wrong. Check your phone number and try again later')
        return
      }

      if (onSuccess) {
        onSuccess(result)
      }
    } catch (err) {
      const errorMsg =
        err instanceof Error ? err.message : 'Something went wrong. Please try again later'

      captureMessage(`Error: ${errorMsg}`, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const sendAuthVerificationRequest = () => {
    void sendAuthRequest(
      API_AUTH_VERIFICATION,
      { phone: getCountryCode() + phone.replaceAll(' ', '') },
      () =>
        setState((prevState) => ({
          ...prevState,
          checkOTP: true
        }))
    )
  }

  const sendAuthVerificationCodeRequest = () => {
    void sendAuthRequest(
      API_AUTH_VERIFICATION_CODE,
      {
        phone: getCountryCode() + phone.replaceAll(' ', ''),
        code: otpCode
      },
      (response) => {
        AuthorizationService.updateToken(response.data?.accessToken as string)
        navigate(PROFILE_TRIPS)
      }
    )
  }

  const handleButtonClick = () => {
    if (checkOTP) {
      void sendAuthVerificationCodeRequest()
    } else if (phone) {
      void sendAuthVerificationRequest()
    }
  }

  const onChangePhoneNumberClick = () => {
    setState((prevState) => ({
      ...prevState,
      checkOTP: false,
      otpCode: ''
    }))
    if (phoneInputRef.current) {
      phoneInputRef.current.focus()
    }
  }

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value.replace(/\D/g, '').slice(0, 6)

    setState((prevState) => ({
      ...prevState,
      otpCode: newValue
    }))
  }

  const onActionClick = () => {
    setState(() => ({
      ...DEFAULT_VALUES
    }))
    navigate(isLogin ? SIGN_UP : SIGN_IN)
    if (phoneInputRef.current) {
      phoneInputRef.current.value = ''
      phoneInputRef.current.focus()
    }
  }

  const getButtonName = useMemo(() => {
    if (isLogin) return 'Log in'
    return checkOTP && !isLogin ? 'Confirm' : 'Continue'
  }, [isLogin, checkOTP])

  const getIsButtonDisabled = () => {
    if (phone.replaceAll(' ', '').length < PHONE_NUMBER_LENGTH) return true
    if (phone && !errors.phone && (isLogin || isPolicyAccepted)) {
      return checkOTP ? otpCode.trim().length !== OTP_LENGTH : false
    }
    return true
  }

  return (
    <>
      <main className="auth-form-cnt">
        <header>
          <h3>{title}</h3>
        </header>
        <div className="auth-form-wrapper">
          <form className="auth-form">
            <div>
              <FormInput
                id="phone"
                type="tel"
                register={register}
                label="Phone Number*"
                value={state.phone}
                onChange={handleFieldChange}
                error={errors.phone}
                readOnly={checkOTP}
                ref={phoneInputRef}
                value={phone}
              />
              {errorMessage && <p className="error-message tiny">{errorMessage}</p>}
            </div>
            {!isLogin && (
              <Checkbox
                appearance="dark"
                checked={isPolicyAccepted}
                onChange={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isPolicyAccepted: !isPolicyAccepted
                  }))
                }
                label={
                  <span className="label-m privacy-policy-link">
                    I agree to the&nbsp;
                    <ExternalLink
                      analyticId={analyticEvents.triggers.externalLink}
                      href={`https://whipss.com${LEGAL}/${PRIVACY}`}
                    >
                      privacy policy
                    </ExternalLink>
                  </span>
                }
              />
            )}
            {checkOTP && (
              <>
                <Box>
                  <p className="label-bold-s">
                    We send a message to: {getCountryCode()}&nbsp;{phone}
                  </p>
                  <Button
                    variant="containedPrimary"
                    name="Change number"
                    onClick={onChangePhoneNumberClick}
                  />
                </Box>
                <div className="otp-group">
                  <input
                    type="number"
                    maxLength={OTP_LENGTH}
                    autoComplete="off"
                    className="otp-input"
                    value={otpCode}
                    onChange={handleOtpChange}
                    ref={otpInputRef}
                  />
                  {OTP_ARRAY.map((_, index) => (
                    <div
                      key={index}
                      className={cn('input-box', { active: otpCode.length === index })}
                    >
                      {otpCode[index]}
                    </div>
                  ))}
                </div>
              </>
            )}
          </form>
          <Button
            variant="linkSecondary"
            name={getButtonName}
            onClick={handleButtonClick}
            isLoading={isLoading}
            disabled={getIsButtonDisabled()}
          />
          <Box justifyContent="center">
            <span className="label-m">{subtitle}</span>&nbsp;
            <button
              onClick={onActionClick}
              className="auth-action label-bold-l"
            >
              {isLogin ? 'Sign up' : 'Log in'}
            </button>
          </Box>
        </div>
      </main>
    </>
  )
}
