import { captureMessage } from '@sentry/react'
import { ChangeEvent } from 'react'
import { ValidationError } from 'yup'

type FieldValues = Record<string, any>

export function useFormFieldValidator<T extends FieldValues>({
  schema,
  setFormData,
  clearErrors,
  setError,
  clearErrorMessage
}: {
  schema: any
  setFormData: (updater: (prevState: T) => T) => void
  clearErrors: (name: keyof T) => void
  setError: (name: keyof T, options: { type: string; message: string }) => void
  clearErrorMessage?: () => void
}) {
  const handleFieldChange = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target

    setFormData((prevState) => ({
      ...prevState,
      [name]: value.trim()
    }))

    if (clearErrorMessage) {
      clearErrorMessage()
    }

    try {
      await schema.validateAt(name, { [name]: value })
      clearErrors(name as keyof T)
    } catch (error) {
      if (error instanceof ValidationError) {
        setError(name as keyof T, {
          type: 'manual',
          message: error.message
        })
      } else {
        captureMessage('An unexpected error occurred')
      }
    }
  }

  return { handleFieldChange }
}
